import React, { useEffect } from "react"
import { PageRendererProps } from "gatsby"
import styled from "styled-components"
import SEO from "@/components/SEO"
import PageTitle from '@/components/PageTitle'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import FadeIn from "@/components/FadeIn"

type ContactsCompletePageProps = PageRendererProps

const title = 'お問い合わせ'
const description = 'ZEN ARCHITECTSへのお問い合わせはこちらのページからお願いします。'

const ContactsComplete: React.FC<ContactsCompletePageProps> = ({ location }) => {
  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {'send_to': 'AW-598792703/pnZUCNryz9wBEP-zw50C'})
    }
  }, [])

  return (
    <>
      <SEO title={title} description={description} pathname={location.pathname} />
      <Root>
        <PageTitle>contact</PageTitle>
        <FadeIn>
          <Icon>
            <FontAwesomeIcon icon={faCheckCircle} />
          </Icon>
        </FadeIn>
        <Message>お問い合わせありがとうございました</Message>
      </Root>
    </>
  )
}

const Root = styled.div`
  @media ${(props) => props.theme.maxSmDown} {
    padding: 80px 0 60px;
    min-height: calc(100vh - 400px);
  }
  @media ${(props) => props.theme.minMdUp} {
    padding: 130px 15px 50px;
    min-height: calc(100vh - 200px);
  }
`

const Icon = styled.div`
  text-align: center;
  font-size: 140px;
  color: ${(props) => props.theme.primaryColor};

  @media ${(props) => props.theme.minMdUp} {
    margin-top: 50px;
  }
`

const Message = styled.p`
  margin: 0 auto;
  width: 100%;
  max-width: 720px;
  text-align: center;
  color: ${(props) => props.theme.primaryColor};
  font-weight: bold;
`

export default ContactsComplete
